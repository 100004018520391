import { defineStore } from 'pinia'

export const useMyHomeStore = defineStore('myHomeStore', {
  state: () => ({
    isBusiness: false,
  }),
  actions: {
    setIsBusiness (isBusiness: boolean) {
      this.isBusiness = isBusiness
    }
  },
  getters: {
  }
})
